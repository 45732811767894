// new styles

$textGrey: #7f7f7f;
$darkGrey: #333333;
$header: 160px;
$headerMobile: 100px;

$unit: 20px;

$mobile: 767px;
$gridWidth: 1100px;

@import "scss/vars";
@import "scss/mixins";
@import "~basscss/css/basscss.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "scss/hamburger";

* {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  //scroll-behavior: smooth;
  @include fp("font-size", 12, 18);
  * {
    box-sizing: inherit;
  }
}

p {
  @include mobile {
    text-align: center;
  }
}

.openingTimes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  background: $darkGrey;
  //text-align: center;
  padding: 10px;
  position: relative;
  z-index: 99999;
  //border-bottom: 1px solid #000;
  .openingTimesLine {
    flex-grow: 1;
    text-align: center;
    padding: 0 20px;
    font-size: 10px;
    color: rgba(#fff, 0.8);
  }
  .openingTimes-Call {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  .openingTimes-Link {
    width: 80px;
    min-width: 80px;
  }
  p,
  a {
    margin: 0;
    color: #fff;
    text-decoration: none;
  }
}

svg.mouse {
  fill: #fff;
  position: absolute;
  bottom: 0;
  z-index: 999;
  left: 50%;
  width: 32px;
  margin-left: -16px;
  transition: all 0.3s ease-in-out;
  @include mobile {
    bottom: 15vh;
    width: 128px;
    margin-left: -64px;
    #mousebody {
      display: none;
    }
  }
}

.mouse circle {
  animation: scroll 1.5s ease infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
    cy: 481;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    cy: 231;
  }
}

.headerWrapper {
  z-index: 999999;
}

header {
  height: $header;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textGrey;
  background: #fff;
  @include mobile {
    flex-direction: column;
    padding-bottom: 15px;
    color: #fff;
  }
  .logo {
    margin: 0 50px;
    @include mobile {
      order: 1;
      width: 192px;
    }
  }
  .textBox {
    display: flex;
    flex-direction: column;
    @include mobile {
      order: 5;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    li {
      margin-left: 10px;
      margin-right: 10px;
      //@include fp(font-size, 12, 16, 320, 1280);
      a {
        color: $textGrey;
        text-decoration: none;
      }
    }

    @include mobile {
      font-size: 12px;
    }

    $linkWidthOnDesktop: 25%;
    @include tablet {
      &.links,
      &.times {
        li {
          min-width: $linkWidthOnDesktop;
          a {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    &.links {
      @include mobile {
        order: 5;
        display: none;
      }
    }
    &.icons {
      @include mobile {
        order: 1;
        margin-bottom: 5px;
        margin-top: 10px;
      }
    }
  }
  .line {
    width: 30vw;
    height: 1px;
    background: grey;
    margin: 15px 0;
    @include mobile {
      display: none;
    }
  }
  .left {
    ul {
      text-align: right;
      justify-content: flex-end;
      li {
        text-align: right;
      }
    }
  }
}

//.navbar {
//  width: 100%;
//  padding: 10px;
//  position: fixed;
//  top: 0;
//  transition: all 0.6s;
//  z-index: 999999;
//}

.navbar--hidden {
  //top: -$header;
  //transform: translateY(-$header);

  @include mobile {
    //transform: translateY(-$headerMobile);
  }
}

section.body {
  //width: 100vw;
  //margin-top: $header;
  width: 100%;
  height: calc(100vh - #{$header});
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  //position: fixed;
  position: relative;
  @include mobile {
    height: 100vh;
    //margin-top: $headerMobile;
    margin-bottom: 0px;
  }
  .text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    //bottom: 40px;
    //left: 40px;
    z-index: 999;
    text-transform: uppercase;
    color: #666;
    text-shadow: 0 0 56px rgba(255, 255, 255, 0.2);
    pointer-events: none;
    h1 {
      margin: 0;
      font-size: 72px;

      @include mobile {
        font-size: 30px;
      }
    }
  }
}

.firstAfterVideo {
  @include tablet {
    margin-top: 100px;
  }
  //  @include mobile {
  //    margin-top: 60vh;
  //  }
}

.video-hero {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: calc(100vh - #{$header});
  padding: 0 !important;
  position: relative;
  @include mobile {
    height: 100vh;
  }
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: calc(100vh - #{$header});
    @include mobile {
      height: 100vh;
    }
  }
  &:after {
    content: "";
    background: rgba(#000, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
  }
}

$video-food-height: 40vh;
.video-food {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: calc(100vh - #{$header});
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  @include mobile {
    height: $video-food-height;
  }
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: calc(100vh - #{$header});
    @include mobile {
      height: $video-food-height;
    }
  }
  &:after {
    content: "";
    background: rgba(#000, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
  }
}

$video-anreise-height-mobile: 40vh;
$video-anreise-height-desktop: 60vh;
.video-anreise {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: $video-anreise-height-desktop;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  @include mobile {
    height: $video-anreise-height-mobile;
  }
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: $video-anreise-height-desktop;
    @include mobile {
      height: $video-anreise-height-mobile;
    }
  }
  &:after {
    content: "";
    background: rgba(#000, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
  }
}

// content area

.wrapper {
  width: 100%;
  max-width: $gridWidth;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  @include mobile {
    padding: 0;
  }
}

.px1 {
  @include mobile {
    padding: 0;
  }
}

img {
  max-width: 100%;
}

.headerbox {
  padding: 40px;
  background: #ffffff;
  border: 1px solid #333333;
  position: relative;
  z-index: 20000;

  h1,
  h2 {
    font-weight: 300;
    @include fp("font-size", 24, 46);
    color: #333333;
    letter-spacing: 2.09px;
    text-align: center;
    margin: 0;
  }

  h4 {
    font-weight: 300;
    @include fp("font-size", 18, 24);
  }

  @include mobile {
    margin-bottom: 40px !important;
    margin-top: -60px !important;

    &:after {
      content: "";
      width: 1px;
      height: 60px;
      display: block;
      background: #000;
      position: absolute;
      left: 50%;
      top: calc(100% + 30px);
    }
  }
}

.textbox {
  background: $darkGrey;
  color: #fff;
  padding: 30px 20px;
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  @include mobile {
    background: #fff;
    color: $darkGrey;
    padding-top: 60px;
    padding-bottom: 100px;
  }
  h2 {
    @include mobile {
      //font-family: "annabelle-jf", cursive;
      //font-weight: 400;
      margin-top: 20px;
      margin-bottom: 0;
      @include fp("font-size", 24, 72);
    }
  }
  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &.button {
      color: $darkGrey;
      text-decoration: none;
    }
    @include mobile {
      color: $darkGrey;
    }
  }
}

.button {
  margin: -40px auto 0;
  background: #ffffff;
  border: 1px solid #333333;
  padding: 16px 32px;
  display: inline-block;
  text-align: center;
  color: rgba(#000, 1);
  text-decoration: none;
  align-self: center;
  transform: translateY(100%);

  @include fp("font-size", 16, 22);
  letter-spacing: 1.64px;

  transition: all 0.8s ease-in-out;
  .in-view & {
  }
}

section.contentWrapper {
  width: 100%;
  display: block;
}

.video-react-big-play-button,
.video-react-control-text,
.video-react-icon-fullscreen,
.video-react-control,
.video-react-time-divider {
  display: none;
}

.objectFit {
  object-fit: cover;
  object-position: center;
  .video-react {
    padding: 0 !important;
    object-fit: cover;
    object-position: center;
    width: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  img,
  video {
    line-height: 0;
  }
}

.eatanddrink {
  @include tablet {
    background: url("./images/karteDesktop.jpg") 50% 75% no-repeat;
    background-size: cover;
  }
  @include mobile {
    margin-bottom: 90px;
  }
}

@include mobile {
  section.content {
    img {
      margin: 0;
      line-height: 0;
      display: block;
    }
  }

  @for $i from 1 through 20 {
    .is-#{$i}-mobile {
      order: $i;
    }
  }

  .mt3 {
    margin-top: 0;
  }

  .mb4 {
    margin-bottom: 0;
  }

  .headerbox {
    margin: 20px;
    transition: all 0.8s ease-in-out;
    transform: translateY(10px);
    background: $darkGrey;
    color: #fff;
    h1,
    h2 {
      color: #fff;
    }
    .in-view & {
      transform: translateY(0px);
    }
  }

  p,
  ul {
    opacity: 0.3;
    transition: all 0.8s ease-in-out;
    .in-view & {
      opacity: 0.8;
    }
  }

  .is-hidden-mobile {
    display: none !important;
  }
}

@include tablet {
  .is-hidden-desktop {
    display: none !important;
  }

  @for $i from 1 through 20 {
    .is-#{$i}-desktop {
      order: $i;
    }
  }

  @for $i from 1 through 30 {
    .mtn#{$i} {
      margin-top: $unit * $i * -1;
    }
  }

  @for $i from 1 through 12 {
    .mln#{$i} {
      margin-left: ($gridWidth / 12) * $i * -1;
    }

    .tl#{$i} {
      transform: translateX(($gridWidth / 12) * $i * -1);
    }
  }

  @for $i from 1 through 12 {
    .mrn#{$i} {
      margin-right: ($gridWidth / 12) * $i * -1;
    }

    .tr#{$i} {
      transform: translateX(($gridWidth / 12) * $i);
    }
  }

  section.content {
    //min-height: 80vh;
    margin-bottom: 20vh;
    transition: all 0.3s ease-in-out;
    z-index: 5000;
    //background: #fff;

    @for $i from 1 through 12 {
      .z#{$i} {
        z-index: 10000 - $i;
        position: relative;
      }
    }

    .textbox {
      z-index: 10000;
      //transform: translateX(-50px);
      //transition: all 1s ease-in-out;
    }
    .slide-up-1 {
      //transform: translateY(50px);
      //transition: all .8s ease-in-out;
      //box-shadow: 0 2px 25px rgba(#000, .1);
      position: relative;
      width: 100%;
      height: 500px;
    }
    .slide-up-2 {
      //transform: translateY(-150px);
      //transition: all 1s ease-in-out;
      //box-shadow: 0 2px 25px rgba(#000, .1);
      position: relative;
    }
    &.in-view {
      //.textbox {
      //  transform: translateX(0px);
      //}
      //
      //.slide-up-1 {
      //  transform: translateY(0px);
      //}
      //
      //.slide-up-2 {
      //  transform: translateY(0px);
      //}
    }
  }
}

.testimonial-wrapper {
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    background: url("./icons/open-quote.svg") center center no-repeat;
    background-size: cover;
    opacity: 0.3;
    top: 20px;
    left: 20px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    background: url("./icons/close-quote.svg") center center no-repeat;
    background-size: cover;
    opacity: 0.3;
    bottom: 20px;
    right: 20px;
  }
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide {
    > div {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .slick-dots {
    bottom: 10px;
    li {
      width: 10px;
    }
  }
  .testimonial {
    text-align: center;
    padding: 30px 0;
    .author {
      opacity: 0.6;
      font-weight: 700;
    }
    blockquote {
      font-size: 18px;
      line-height: 1.8;
    }
  }
}

.comeAndFindUs {
  margin-bottom: 0 !important;
  .headerbox {
    transform: translateY(50%);
    @include mobile {
      margin: 0 !important;
      padding: 40px 20px;
    }
    &:after {
      display: none;
    }
  }
}

footer {
  background: $darkGrey;
  padding-top: 50px;
  color: #fff;
  margin-top: -1px;
  font-size: 14px;
  a {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  span {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  p {
    opacity: 1;
    text-align: left;
  }
  ul {
    list-style: none;
    padding: 0;
    opacity: 1;
  }
  h2 {
    font-weight: 400;
  }
}

.iconwrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  img {
    width: 24px;
    margin-right: 10px;
  }
  p {
    margin: 0;
    opacity: 0.8;
  }
}

.contactForm {
  padding: 20px;
  margin: 0;
  width: 100%;
  max-width: 600px;
  form {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 10px;
    }
    > button {
      margin-top: 10px;
      background: #ffffff;
      border: 1px solid #333333;
      padding: 16px 32px;
      border-radius: 0;
      height: auto;
      font-size: 18px;

      @include tablet {
        width: 50%;
        align-self: flex-end;
      }
    }
  }
}

.bottomBar {
  //background: #000;
  margin-top: 50px;
  padding: 30px;
  text-align: center;
  with: 100%;
}

.instagram {
  h2 {
    text-align: center;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-bottom: 20px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 24px;
      color: #000;
      img {
        margin-right: 10px;
        margin-top: 3px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.instagramImages {
  display: flex;
  flex-wrap: wrap;
  .instagramImage {
    width: calc(100% / 6);
    height: calc(100vw / 6);
    position: relative;
    overflow: hidden;
    display: flex;
    @include mobile {
      width: calc(100% / 2);
      height: 50vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hover {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000, 0.8);
      color: #fff;
      padding: 20px;
      transform: translateY(100%);
      transition: all 0.3s ease-in-out;
      font-size: 12px;
      @include mobile {
        display: none;
      }
    }
    @include tablet {
      &:hover {
        .hover {
          transform: translateY(0%);
        }
      }
    }
  }
}

.divider{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
  min-height: 400px;
  background: #ccc;
  .half{
    flex: 3;
    display: flex;
    transition: all 1s ease;
    background: #ccc center center no-repeat;
    position: relative;
    &.drinks{
      background-image: url('./images/Fotokuchl-0427.jpg');
    }
    &.eat{
      background-image: url('./images/Fotokuchl-0248.jpg');
    }
    &.left{
      &:after{
        content: "";
        position: absolute;
        right: -5px;
        width: 10px;
        top: 0;
        bottom: 0;
        background: #fff;
        display: block;
      }
    }
    .text{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba(#000, .3);
      transition: all .8s ease;
      text-decoration: none;
      color: #fff;
      text-shadow: 0px 0px 10px rgba(#000, .3);
      h3{
        font-size: 40px;
        margin-bottom: 0;
      }
      .button{
        display: inline-block;
        width: 250px;
        color: #fff;
        background: transparent;
        padding: 15px;
        border: 2px solid #fff;
        margin-top: 0px;
        transition: all .3s ease;
        &:hover{
          color: #000;
          background-color: #fff;
        }
      }
    }
    &:hover{
      flex: 4;
      .text{
        background-color: rgba(#000, .7);
      }
    }
  }
}
